var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{attrs:{"id":"switch-map-menu"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSwitchMapMenu),expression:"showSwitchMapMenu"}],ref:"switchMap",staticClass:"switch-map",style:({
      left: _vm.pointLeft + 'px',
      top: _vm.pointTop + 'px',
      opacity: _vm.opacity,
    })},[(_vm.spinning)?_c('div',{staticClass:"loading-box"},[_c('a-spin',{staticClass:"loading-child",attrs:{"tip":"Loading...","spinning":_vm.spinning,"delay":500}})],1):_c('main',[(JSON.stringify(_vm.folder) !== '{}')?_c('section',{staticClass:"folder-container"},[_c('div',{staticClass:"up-one-level",on:{"click":function($event){$event.stopPropagation();_vm.upperLevelFolder.pop();
            _vm.getFirstPageSwitchMapData(_vm.folderParentId);}}},[_c('label',{staticClass:"upper-level-box"},[_c('img',{ref:"upperLevelImg",staticClass:"return-img",attrs:{"src":require("../../../assets/img/mindNewEditMenuIocn/return.svg"),"alt":""}})]),_c('span',[_vm._v(_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Group_Mind_Up_One_Level)))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"folder-name",attrs:{"title":_vm.folder.name}},[_vm._v(" "+_vm._s(_vm.folder.name)+" ")])]):_vm._e(),(_vm.SwitchMapData.length > 0)?_c('main',_vm._l((_vm.SwitchMapData),function(item,index){return _c('div',{key:index,staticClass:"menulist-each-big-box"},[(!_vm.arvhiveMap && item.isDir)?_c('section',{staticClass:"menulist-each",on:{"click":function($event){$event.stopPropagation();return _vm.getFirstPageSwitchMapData(item.id)}}},[_c('span',{staticClass:"fileiconfont my-mind-icon",class:_vm.folderColor[item.colorCode]},[_vm._v("")]),_c('div',{staticClass:"my-mind-list-title"},[_c('span',{staticClass:"my-mind-main-heading",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])]):(item.type == 0 && _vm.gmap)?_c('section',{staticClass:"menulist-each",on:{"click":function($event){$event.stopPropagation();_vm.upperLevelFolder.push(item);
              _vm.getFirstPageSwitchMapData(item.id);}}},[(item.type == 0)?_c('img',{staticClass:"mind-each-file",attrs:{"src":require("../../../assets/img/groupImg/file-icon.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"my-mind-list-title"},[_c('span',{staticClass:"my-mind-main-heading",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])]):(_vm.arvhiveMap && item.isDir)?_c('section',{staticClass:"menulist-each",on:{"click":function($event){$event.stopPropagation();_vm.upperLevelFolder.push(item);
              _vm.getFirstPageSwitchMapData(item.id);}}},[(_vm.arvhiveMap)?_c('img',{staticClass:"mind-each-file",attrs:{"src":require("../../../assets/img/arvhive/bookcase.svg"),"alt":""}}):_vm._e(),_c('div',{staticClass:"my-mind-list-title"},[_c('span',{staticClass:"my-mind-main-heading",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])]):_c('section',{staticClass:"menulist-each",on:{"click":function($event){return _vm.checkMenuItem(item)}}},[_c('img',{directives:[{name:"real-img",rawName:"v-real-img",value:(_vm.checkImgSrc(item.cover)),expression:"checkImgSrc(item.cover)"}],attrs:{"src":require("../../../assets/img/user/plate_plan_4.jpg"),"alt":""}}),_c('div',{staticClass:"my-mind-list-title"},[_c('span',{staticClass:"my-mind-main-heading",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"my-mind-main-subheading",attrs:{"title":item.subtitle}},[_vm._v(" "+_vm._s(item.subtitle)+" ")])])]),_c('div',{staticClass:"line"})])}),0):_c('main',{staticClass:"no-dom"},[_c('img',{staticClass:"no-dom-img",attrs:{"src":require("../../../assets/img/mindNewEditMenuIocn/NoDocuments.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Group_Mind_No_Documents)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }